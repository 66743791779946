import React from "react"
import Layout from "../../../components/sermonLayoutPhoenix"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout sermonSrc="https://www.youtube.com/embed/3YTcyeDz6ag">
    <SEO title="Healthy Vision - Imperatives" />
  </Layout>
)

export default SermonPost
